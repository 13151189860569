<template>
  <Container class="">
    <div class="flex flex-wrap items-end gap-6 border-gray-300 lg:justify-center lg:flex-nowrap lg:gap-8"
         :class="type === 'primary' ? '' : 'border-b pb-7'"
    >
      <div class="w-full lg:w-auto">
        <h2 class="leading-tight text-white"
            :class="[type === 'primary' ? 'text-5xl lg:text-6xl' : 'text-4xl lg:text-5xl', !cta ? 'lg:text-center max-w-3xl' : 'max-w-2xl']"
            v-html="refactored"
        />
        <div v-if="copy"
             class="mt-6 text-lg text-white lg:mt-8"
             :class="[!cta ? 'lg:text-center max-w-3xl' : 'max-w-2xl']"
             v-html="copy"
        />
      </div>

      <ButtonBase v-if="cta"
                  class="lg:flex-shrink-0 lg:ml-auto"
                  :type="cta.type"
                  :href="cta.href"
                  :label="cta.title"
                  @click="handleClick(cta)"
      />
    </div>
  </Container>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import type { CtaType } from '../../../types/button';
import { useNuxtApp } from '#imports';
import { useRoute } from 'vue-router';

interface SectionHeadingCtaProps {
  title: string;
  copy?: string;
  cta: CtaType;
  type?: 'primary' | 'secondary';
}

const props = withDefaults(defineProps<SectionHeadingCtaProps>(), {
  type: 'primary',
  copy: ''
});

const refactored = computed(() => props.title.replace(/\n/g, ' <br class="hidden md:inline" />'));

const { $event } = useNuxtApp();
const route = useRoute();

function handleClick(cta: CtaType) {
  if (!cta || !cta.title) return;
  if (route.path.includes('for-media')) return $event('Contact', { props: { 'Media page': cta.title } });
}
</script>

<style scoped>
h2 strong {
  @apply text-red-500;
}
</style>
